@import '_utility';

.splash--wrap {
    background: url('/assets/img/Studio-Piccagli-Reggiolo_header-mission.jpg') no-repeat center;
    background-size: cover;
    text-align: center;
    padding: 80px 0 60px 0;
}

.splash--layout {
    @include gabbia;
}

.splash--content {

}

.splash--title {
    font-family: $roboto;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
    color: white;
    margin-bottom: 40px;

    @include media('>=tablet') {
        font-size: 65px;
    }

    @include media('>=desktop') {
        font-size: 72px;
    }
}

.splash--subtitle {
    font-size: 18px;
    line-height: $line;
    color: white;
    margin-bottom: 40px;

    @include media('>=tablet') {
        font-size: 22px;
    }
}

.studio--wrap {
    padding: 40px 0;

    @include media('>=tablet') {
        padding: 80px 0 0 0;
    }
}

.studio--content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.studio--intro {
    font-size: 22px;
    line-height: $line;
    margin-bottom: 40px;
    width: 100%;

    @include media('>=tablet') {
        width: 50%;
        padding-right: 40px;
        margin-bottom: 0;
    }
}

.studio--txt {
    width: 100%;

    @include media('>=tablet') {
        width: 50%;
        padding-left: 40px;
        border-left: 1px solid $color;
    }

    p {
        line-height: $line;
    }

    p + p {
        margin-top: 20px;
    }
}

.bullet--wrap {
    padding: 40px 0;

    @include media('>=tablet') {
        padding: 60px 0;
    }
}

.bullet--track {
    display: flex;
    flex-wrap: wrap;
}

.bullet--item {
    width: 100%;
    text-align: center;
    padding: 30px;

    @include media('>=tablet') {
        width: 50%;
    }

    @include media('>=desktop') {
        width: 25%;
    }
}

.bullet--img {
    width: 70px;
    height: 70px;
    margin: 0 auto 20px auto;

    img {
        width: 100%;
        height: 100%;
    }
}

.bullet--title {
    font-size: 18px;
    font-weight: bold;
    color: $rosso;
    text-transform: uppercase;
    margin-bottom: 10px;
}


.cta--wrap {
    background: url('/assets/img/icons-bg.svg'),url('/assets/img/cog-reverse-bg.svg'),linear-gradient(to bottom, $grigio 0%, #fff 100%);
    background-repeat: repeat-x, no-repeat, no-repeat;
    background-position: center bottom 20px, left bottom;
    background-size: auto 130px, cover, auto;
    padding: 40px 0 180px 0;

    @include media('>=tablet') {
        background: url('/assets/img/icons-bg.svg'),url('/assets/img/cog-reverse-bg.svg'),linear-gradient(to bottom, $grigio 0%, #fff 100%);
        background-repeat: repeat-x, no-repeat, no-repeat;
        background-position: center bottom 20px, left bottom;
        background-size: auto 200px, cover, auto;
        padding: 80px 0 300px 0;
    }

    @include media('>=desktop') {
        // background: url('/assets/img/cog-reverse-bg.svg') no-repeat left bottom,linear-gradient(to top, $grigio 0%, #fff 100%);
        background-size: 100% auto;
        background: url('/assets/img/icons-bg.svg'),url('/assets/img/cog-reverse-bg.svg'),linear-gradient(to bottom, $grigio 0%, #fff 100%);
        background-repeat: repeat-x, no-repeat, no-repeat;
        background-position: center bottom 20px, left bottom;
        background-size: auto 300px, cover, auto;
        padding: 80px 0 370px 0;
    }
}

.cta--title {
    font-family: $roboto;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: $rosso;
    margin-bottom: 30px;

    @include media('>=tablet') {
        font-size: 42px;
    }
}

.cta--subtitle {
    text-align: center;
    font-size: 17px;
    max-width: 600px;
    margin: 0 auto 40px auto;

    @include media('>=tablet') {
        font-size: 20px;
    }
}
